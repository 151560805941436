if (self.importScripts) {
  const { main: sdkAPI } = require('./plugin.worker-sdk');
  const sdkAPIHandler = sdkAPI(self, self.location.origin);

  const { main: datalabAPI } = require('./plugin.worker-datalab');
  const datalabAPIHandler = datalabAPI(self, self.location.origin);

  self.onmessage = (ev) => {
    const type = (ev.data && ev.data.type) || ev.type;

    if (type === sdkAPIHandler.type) {
      return sdkAPIHandler.handler(ev);
    }
    if (type === datalabAPIHandler.type) {
      return datalabAPIHandler.handler(ev);
    }
  };
}
