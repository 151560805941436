import { formatAsSeeqError, handleResponse, trimAndEncodePath } from '@/webWorkers/plugin.worker.utilities';

export function main(worker: Worker, baseUrl: string): { type: string; handler: (ev: MessageEvent) => any } {
  worker.postMessage('Datalab handler loaded');

  const getProject = async (event: MessageEvent) => {
    const {
      requestId,
      payload: { projectName },
    } = event.data;
    const params = new URLSearchParams({
      types: 'Project',
      filters: `name==${projectName}`,
    });
    await handleResponse(
      requestId,
      worker,
      () =>
        fetch(`${baseUrl}/api/items?${params}`, {
          method: 'GET',
          credentials: 'same-origin',
          headers: { Accept: 'application/vnd.seeq.v1+json' },
        }),
      true,
    ).then((response) => {
      const projects = response?.['items'] as { id: string }[];
      if (projects?.length === 1) {
        const projectId = projects?.[0]?.id;
        worker.postMessage({ requestId, payload: { projectId } });
      } else if (!projects?.length) {
        const message = `No project named '${projectName}' was found.`;
        worker.postMessage({ requestId, isError: true, payload: formatAsSeeqError(message) });
      } else {
        const projectIds = projects.map((project) => project?.id);
        const message = `More than one project named '${projectName}' was found. Project IDs: ${projectIds}`;
        worker.postMessage({ requestId, isError: true, payload: formatAsSeeqError(message) });
      }
    });
  };

  const callApi = async (event: MessageEvent) => {
    const {
      requestId,
      payload: { projectId, notebookName, path, method, query, headers },
    } = event.data;
    const params = Object.keys(query || {}).length > 0 ? `?${new URLSearchParams(query)}` : '';
    const justPath = trimAndEncodePath(path);
    const apiUrl =
      `${baseUrl}/data-lab/${encodeURIComponent(projectId)}/sdl/api/notebooks/` +
      `${encodeURIComponent(notebookName)}/endpoints/${justPath}${params}`;

    // We support a JSON-compatible body
    let body: string;
    if (['POST', 'PUT', 'PATCH'].includes(method) && event?.data?.payload?.body) {
      try {
        body = JSON.stringify(event.data.payload.body);
        headers['Content-Type'] = 'application/json';
      } catch (error) {
        worker.postMessage({ requestId, isError: true, payload: formatAsSeeqError(error) });
        return;
      }
    }

    await handleResponse(requestId, worker, () =>
      fetch(apiUrl, {
        method,
        body,
        headers,
        credentials: 'same-origin',
      }),
    );
  };

  return {
    type: 'datalab',
    handler: async (event) => {
      if (!event.data) {
        console.warn(`Received event without data`, event);
      } else if (event.data.fn === 'callApi') {
        await callApi(event);
      } else if (event.data.fn === 'getProject') {
        await getProject(event);
      } else {
        console.warn(`Received event with invalid function ${event.data.fn}`, event);
      }
    },
  };
}
