import { formatAsSeeqError, handleResponse, trimAndEncodePath } from '@/webWorkers/plugin.worker.utilities';

export function main(worker: Worker, baseUrl: string): { type: string; handler: (ev: MessageEvent) => any } {
  worker.postMessage('SDK handler loaded');

  const callApi = async (event: MessageEvent) => {
    const {
      requestId,
      payload: { path, method, query, headers },
    } = event.data;
    const params = Object.keys(query || {}).length > 0 ? `?${new URLSearchParams(query)}` : '';
    const justPath = trimAndEncodePath(path);
    const apiUrl = `${baseUrl}/api/${justPath}${params}`;

    // We support a Seeq JSON-compatible body
    let body: string;
    if (['POST', 'PUT', 'PATCH'].includes(method) && event?.data?.payload?.body) {
      try {
        body = JSON.stringify(event.data.payload.body);
        headers['Content-Type'] = 'application/vnd.seeq.v1+json';
      } catch (error) {
        worker.postMessage({ requestId, isError: true, payload: formatAsSeeqError(error) });
        return;
      }
    }

    await handleResponse(requestId, worker, () =>
      fetch(apiUrl, {
        method,
        body,
        credentials: 'same-origin',
        headers: { Accept: 'application/vnd.seeq.v1+json', ...headers },
      }),
    );
  };

  return {
    type: 'sdk',
    handler: async (event) => {
      if (!event.data) {
        console.warn(`Received event without data`, event);
      } else if (event.data.fn === 'callApi') {
        await callApi(event);
      } else {
        console.warn(`Received event with invalid function ${event.data.fn}`, event);
      }
    },
  };
}
